body {
  margin-top: 70px;
}

:root {
    --fc-small-font-size: .85em;
    --fc-page-bg-color: #fff;
    --fc-neutral-bg-color: rgba(208, 208, 208, 0.3);
    --fc-neutral-text-color: #808080;
    --fc-border-color: #ddd;
        
    --fc-button-text-color: #fff;
    --fc-button-bg-color: #193AA5;
    --fc-button-border-color: #193AA5;
    --fc-button-hover-bg-color: #193AA5;
    --fc-button-hover-border-color: #193AA5;
    --fc-button-active-bg-color: #1773c9;
    --fc-button-border-color: #193AA5;

    --fc-event-bg-color: #3788d8;
    --fc-event-border-color: #3788d8;
    --fc-event-text-color: #fff;
    --fc-event-selected-overlay-color: rgba(0, 0, 0, 0.25);

    --fc-more-link-bg-color: #d0d0d0;
    --fc-more-link-text-color: inherit;

    --fc-event-resizer-thickness: 8px;
    --fc-event-resizer-dot-total-width: 8px;
    --fc-event-resizer-dot-border-width: 1px;

    --fc-non-business-color: rgba(215, 215, 215, 0.3);
    --fc-bg-event-color: rgb(143, 223, 130);
    --fc-bg-event-opacity: 0.3;
    --fc-highlight-color: rgba(188, 232, 241, 0.3);
    --fc-today-bg-color: rgba(255, 220, 40, 0.15);
    --fc-now-indicator-color: red;

  }

  .fc {
    font-family: "franklin-gothic-atf", sans-serif;
    font-weight: 200;
    font-style: normal;
    src: url('https://use.typekit.net/knm8cnu.css') format('truetype');
  }

  @media (pointer:none), (pointer:coarse) {
    /* Phones */
    @media (min-width:240px) {
      #gcal {
        padding-top: 10px;
      }
    }

    /* Tablets */
    @media (min-width:641px) {}
}

.bounce {
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}